import { getApiConfig } from "../authHelper"

import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

export const getAllUser = (queryData) => {
  const { page, limit, search } = queryData
  return get(`${url.USER}?sortOrder=-1${search && `&search=${search}`}${page && limit && `&page=${page}&limit=${limit}`}`, getApiConfig())
}

export const updateUser = (id, data) => {
  return put(`${url.USER}/${id}`, data, getApiConfig())
}

export const createUser = data => {
  return post(`${url.USER}/signup`, data, getApiConfig())
}
