import { getApiConfig } from "../authHelper"

import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

export const getAllSubscription = paymentStatus => {
  if (paymentStatus !== "all" && paymentStatus) {
    return get(
      `${url.SUBSCRIPTION}/all?paymentStatus=${paymentStatus}&sortBy=createdAt&sortOrder=-1`,
      getApiConfig()
    )
  }
  return get(`${url.SUBSCRIPTION}/all?sortBy=createdAt&sortOrder=-1`, getApiConfig())
}

export const getSubscription = id => {
  return get(`${url.SUBSCRIPTION}?_id=${id}`, getApiConfig())
}

export const getReport = (startDate, endDate) => {
  if (startDate && endDate) {
    return get(
      `${url.USER}/admin/reports?startDate=${startDate}&endDate=${endDate} `,
      getApiConfig()
    )
  }
  return get(`${url.USER}/admin/reports`, getApiConfig())
}

export const createSubscription = data => {
  return post(`${url.SUBSCRIPTION}`, data, getApiConfig())
}

export const updateSubscription = (id, data) => {
  return put(`${url.SUBSCRIPTION}/${id}`, data, getApiConfig())
}

// mark as paid
export const markAsPaidSubscription = id => {
  return put(`${url.SUBSCRIPTION}/mark-as-paid/${id}`, {}, getApiConfig())
}

export const deleteSubscription = id => {
  return del(`${url.SUBSCRIPTION}/${id}`, getApiConfig())
}

export const createSubscriptionV2 = data => {
  return post(`${url.SUBSCRIPTION_V2}`, data, getApiConfig())
}

export const getAllSubscriptionV2 = queryData => {
  const { page, limit, search, allValue, startDate, endDate } = queryData
  const queryParams = new URLSearchParams();

  queryParams.append("sortOrder", "-1");
  if (search) queryParams.append("search", search);
  if (page && limit) {
    queryParams.append("page", page);
    queryParams.append("limit", limit);
  }
  if (startDate && endDate) {
    queryParams.append("startDate", startDate);
    queryParams.append("endDate", endDate);
  }
  if (allValue !== "all") {
    queryParams.append("paymentStatus", allValue);
  }
  return get(`${url.SUBSCRIPTION_V2}/all?${queryParams.toString()}`, getApiConfig());
}

export const markAsPaidSubscriptionV2 = id => {
  return put(`${url.SUBSCRIPTION_V2}/mark-as-paid/${id}`, {}, getApiConfig())
}

export const deleteSubscriptionV2 = id => {
  return del(`${url.SUBSCRIPTION_V2}/${id}`, getApiConfig())
}

export const updateSubscriptionV2 = (id, data) => {
  return put(`${url.SUBSCRIPTION_V2}/${id}`, data, getApiConfig())
}