import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap"

const SubscriptionModal = props => {
  const { isOpen, toggle, data, isInvoice } = props

  {
    if (data !== null)
      return (
        <>
          <Modal
            isOpen={isOpen}
            size={isInvoice ? "xl" : ""}
            role="dialog"
            autoFocus={true}
            centered={true}
            className={isInvoice ? "modal-fullscreen" : ""}
            tabIndex="-1"
            toggle={toggle}
          >
            <ModalHeader toggle={toggle}>
              Manage Subscription details
            </ModalHeader>
            <ModalBody>
              {isInvoice ? (
                <iframe src={data?.paymentId?.invoiceUrl} className="w-100 h-100" />
              ) : (
                <Table>
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{data?.userId?.userName}</td>
                    </tr>
                    <tr>
                      <th>Plan Amount</th>
                      <td>{data?.paymentId?.planAmount}</td>
                    </tr>
                    <tr>
                      <th>Expiry Date</th>
                      <td>{new Date(data.expiryDate).toLocaleDateString()}</td>
                    </tr>
                    <tr>
                      <th>Plan Type</th>
                      <td>{data?.planId?.planType}</td>
                    </tr>

                    <tr>
                      <th>Payment Mode</th>
                      <td>{data?.paymentId?.paymentMode}</td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </ModalBody>
          </Modal>
        </>
      )
  }
}

SubscriptionModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default SubscriptionModal
