import { v4 as uuidv4 } from "uuid"

export const getAuthToken = () => {
  return localStorage.getItem("authToken")
    ? localStorage.getItem("authToken")
    : null
}

export const setAuthToken = token => {
  if (!token) {
    return false
  }
  localStorage.setItem("authToken", token)
  return true
}

export const removeAuthToken = () => {
  localStorage.removeItem("authToken")
  return true
}

export const getUserInfo = () => {
  let userInfo = localStorage.getItem("userInfo")
  return userInfo ? JSON.parse(userInfo) : null
}

export const setUserInfo = userInfo => {
  if (!userInfo) {
    return false
  }

  userInfo = JSON.stringify(userInfo)
  localStorage.setItem("userInfo", userInfo)
  return true
}

export const removeUserInfo = () => {
  localStorage.removeItem("userInfo")
  return true
}

export const getApiConfig = isFormData => {
  let config = {}
  let token = getAuthToken()

  console.log("API Config", token)
  if (token) {
    let headers = {}
    if (isFormData) {
      headers = {
        "Content-Type": `multipart/form-data`,
        "ngrok-skip-browser-warning": "any",
        "x-auth-token": token,
      }
    } else {
      headers = {
        "x-auth-token": token,
        "ngrok-skip-browser-warning": "any",
      }
    }

    config = {
      ...config,
      headers,
    }
  }
  return config
}
