import Breadcrumb from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import dayjs from "dayjs"
import {
    getAllSubscriptionV2,
} from "helpers/backendHelpers/manageSubscription"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import {
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    Container,
} from "reactstrap"
import SubscriptionModal from "./ViewModal"
import DynamicTable from "components/Common/DynamicTable"
import { DateRangePicker } from "rsuite"

const Subscription = props => {
    const [loading, setLoading] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [modal1, setModal1] = useState(false)
    const [isInvoice, setIsInvoice] = useState(false)
    const [pageIndex, setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalRecords, setTotalRecords] = useState(0)
    const [search, setSearch] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("all");
    const [subscriptionData, setSubscriptionData] = useState([])
    const [date, setDate] = useState({
        startDate: null,
        endDate: null,
    })

    const [form, setForm] = useState({
        planId: "",
        userId: "",
        planAmount: 0,
        discountAmount: 0
    })

    //* toggle view modal
    const toggleViewModal = () => {
        if (modal1 == false) {
            setModal1(true)
        } else {
            setIsInvoice(false)
            setModal1(false)
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: "User Name",
                accessor: "userDetails",
                filterable: true,
                Cell: cellProps => {
                    return cellProps.row.original?.userId?.userName || "-"
                },
            },
            {
                Header: "Invoice No.",
                accessor: "invoiceNo",
                filterable: true,
                Cell: cellProps => {
                    return cellProps.row.original?.paymentId?.invoiceNo || "-"
                },
            },
            {
                Header: "Invoice Date",
                accessor: "createdAt",
                filterable: true,
                Cell: cellProps => {
                    return dayjs(cellProps.row.original?.paymentId?.invoiceDate).format("DD/MM/YYYY")
                },
            },
            {
                Header: "Plan Name",
                accessor: "name",
                filterable: true,
                Cell: cellProps => {
                    return cellProps.row.original?.planId?.name || "-"
                },
            },
            {
                Header: "Plan Amount",
                accessor: "planAmount",
                filterable: true,
                Cell: cellProps => {
                    return cellProps.row.original?.paymentId?.planAmount || "-"
                },
            },
            {
                Header: "Payment Mode",
                accessor: "paymentMode",
                filterable: true,
                Cell: cellProps => {
                    return cellProps.row.original?.paymentId?.paymentMode || "-"
                },
            },
            {
                Header: "Expiration Date",
                accessor: "expiryDate",
                filterable: true,
                Cell: cellProps => {
                    const data = cellProps.row.original
                    return <span>{new Date(data?.expiryDate).toLocaleDateString()}</span>
                },
            },
            {
                Header: "Active",
                accessor: "isActive",
                disableFilters: true,
                Cell: cellProps => {
                    const cellData = cellProps.row.original
                    return (
                        <div className="form-check form-switch form-switch-md">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={`isActive-${cellData._id}`}
                                name={`isActive-${cellData._id}`}
                                defaultChecked={cellData.isActive}
                                onChange={e => {
                                    let { checked, name } = e.target
                                    document.getElementById(name).checked = checked
                                    return handleUpdateSubscriptionStatus(cellData._id, {
                                        isActive: checked,
                                    })
                                }}
                            />
                        </div>
                    )
                },
            },
            {
                Header: "Expired",
                accessor: "isExpired",
                disableFilters: true,
                Cell: cellProps => {
                    const cellData = cellProps.row.original
                    return (
                        <div className="form-check form-switch form-switch-md">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={`isExpired-${cellData._id}`}
                                name={`isExpired-${cellData._id}`}
                                defaultChecked={cellData.isExpired}
                                onChange={e => {
                                    let { checked, name } = e.target
                                    document.getElementById(name).checked = checked
                                    return handleUpdateSubscriptionStatus(cellData._id, {
                                        isExpired: checked,
                                    })
                                }}
                            />
                        </div>
                    )
                },
            },

            {
                Header: "Action",
                accessor: "action",
                disableFilters: true,
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <i
                                className="bx bxs-file-pdf font-size-24 cursor-pointer"
                                id="invoiceTooltip"
                                onClick={() => {
                                    setForm(cellProps?.row?.original)
                                    setIsInvoice(true)
                                    toggleViewModal()
                                }}
                            />
                            <UncontrolledTooltip placement="top" target="invoiceTooltip">
                                Invoice
                            </UncontrolledTooltip>
                            <Link
                                to="#"
                                className="text-primary"
                                onClick={() => {
                                    setForm(cellProps?.row?.original)
                                    toggleViewModal()
                                }}
                            >
                                <i className="mdi mdi-eye font-size-18" id="viewTooltip" />
                                <UncontrolledTooltip placement="top" target="viewTooltip">
                                    View
                                </UncontrolledTooltip>
                            </Link>

                        </div>
                    )
                },
            },
        ],
        []
    )

    const fetchAllSubscriptionV2 = async (search = "", allValue = "all", startDate = null, endDate = null) => {
        try {
            setLoading(true)
            const params = { page: pageIndex + 1, limit: pageSize, search, allValue, startDate, endDate }
            let response = await getAllSubscriptionV2(params)
            setTotalRecords(response?.payload?.count)
            response = response?.payload?.data

            setSubscriptionData(response)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (search === "") {
            fetchAllSubscriptionV2(search, paymentStatus)
        }
    }, [search])

    useEffect(() => {
        document.title = "User | React Admin & User Template"
        fetchAllSubscriptionV2(search, paymentStatus, date.startDate, date.endDate)
    }, [pageIndex, pageSize, paymentStatus, date])

    return (
        <React.Fragment>
            <SubscriptionModal
                isOpen={modal1}
                isInvoice={isInvoice}
                toggle={toggleViewModal}
                data={form}
            />

            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title="Manage Subscription" />
                    <Row className="subscription-page">
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <div
                                        className="btn-group btn-group-example mb-3"
                                        role="group"
                                        style={{ position: "absolute", left: "35%", zIndex: "1" }}
                                    >
                                        <button
                                            onClick={() => setPaymentStatus("all")}
                                            type="button"
                                            className={`btn btn-outline-primary w-sm ${paymentStatus === "all" ? "active-subscription-status" : ""
                                                }`}
                                        >
                                            All
                                        </button>
                                        <button
                                            onClick={() => setPaymentStatus("paid")}
                                            type="button"
                                            className={`btn btn-outline-primary w-sm ${paymentStatus === "paid" ? "active-subscription-status" : ""
                                                }`}
                                        >
                                            Paid
                                        </button>
                                        <button
                                            onClick={() => setPaymentStatus("pending")}
                                            type="button"
                                            className={`btn btn-outline-primary w-sm ${paymentStatus === "pending" ? "active-subscription-status" : ""
                                                }`}
                                        >
                                            Unpaid
                                        </button>
                                    </div>
                                    <div style={{ position: "absolute", right: 0, zIndex: "1" }}>
                                        <DateRangePicker
                                            onClean={() => {
                                                setDate({
                                                    startDate: null,
                                                    endDate: null,
                                                })
                                            }}
                                            placeholder="Expiry Date"
                                            className="range-picker mb-2 date-picker-div"
                                            onChange={e => {
                                                if (e !== null) {
                                                    let [startDate, endDate] = e
                                                    startDate =
                                                        dayjs(startDate).format("YYYY-MM-DD").split("T")[0] +
                                                        "T00:00:00.000Z"
                                                    endDate =
                                                        dayjs(endDate).format("YYYY-MM-DD").split("T")[0] +
                                                        "T00:00:00.000Z"
                                                    setDate({
                                                        startDate: startDate,
                                                        endDate: endDate,
                                                    })
                                                }
                                            }}
                                            showOneCalendar
                                            format="dd/MM/yyyy"
                                        />
                                    </div>
                                    <DynamicTable
                                        columns={columns}
                                        data={subscriptionData}
                                        isLoading={loading}
                                        pageIndex={pageIndex}
                                        pageSize={pageSize}
                                        pageCount={Math.ceil(totalRecords / pageSize)}
                                        canPreviousPage={pageIndex > 0}
                                        canNextPage={pageIndex < Math.ceil(totalRecords / pageSize) - 1}
                                        gotoPage={setPageIndex}
                                        previousPage={() => setPageIndex(pageIndex - 1)}
                                        nextPage={() => setPageIndex(pageIndex + 1)}
                                        setPageSize={setPageSize}
                                        state={{ pageIndex, pageSize }}
                                        search={search}
                                        handleSearchChange={(e) => {
                                            setSearch(e.target.value)
                                        }}
                                        handleSearchClick={() => fetchAllSubscriptionV2(search || "", paymentStatus)}
                                        totalRecords={totalRecords}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Subscription
