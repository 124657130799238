import React from 'react';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import { Button, Col, Input, Row, Spinner } from 'reactstrap';

// Reusable Table Component
const DynamicTable = ({
    columns,
    data,
    totalRecords = 0,
    pageCount: controlledPageCount,
    isLoading,
    filterTypes,
    pageIndex = 0,
    pageSize,
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    addButtonLabel,
    handleAddButtonClick,
    search,
    handleSearchChange,
    handleSearchClick,
    state: { pageIndex: currentPageIndex, pageSize: currentPageSize },
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setFilter,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex,
                pageSize,
            },
            manualPagination: true,
            manualSortBy: true,
            manualFilters: true,
            pageCount: controlledPageCount,
        },
        useFilters,
        useSortBy,
        usePagination
    );

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 align-items-center">
                    <select
                        className="form-select form-select"
                        value={currentPageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                        style={{ width: "150px" }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                    {handleSearchChange && handleSearchClick && <div className="d-flex gap-2 align-items-center">
                        <Input
                            value={search}
                            onChange={handleSearchChange}
                            placeholder="Search..."
                            style={{ width: '250px' }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && search !== "") {
                                    handleSearchClick()
                                }
                            }}
                        />
                        <Button
                            color="primary"
                            onClick={handleSearchClick}

                            disabled={isLoading}
                        >
                            Search
                        </Button>
                    </div>}
                </div>
                {addButtonLabel && (
                    <>
                        <Button
                            type="button"
                            color="primary"
                            className="btn mb-2 me-2"
                            onClick={handleAddButtonClick}
                        >
                            {addButtonLabel && (
                                <>
                                    <i className="mdi mdi-plus me-1" />
                                    {addButtonLabel}
                                </>
                            )}
                        </Button>
                    </>
                )}
            </div>

            <table {...getTableProps()} className="table">
                <thead className='custom-header-css'>
                    {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, i) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {isLoading ?
                        <tr>
                            <td colSpan={columns?.length} className='text-center'>
                                <Spinner color="info" type="grow" />
                            </td>
                        </tr> : rows.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell, i) => {
                                        return <td {...cell.getCellProps()} key={i}>{cell.render('Cell')}</td>;
                                    })}
                                </tr>
                            );
                        })}
                </tbody>
            </table>

            {/* Pagination */}
            <Row className="justify-content-md-end justify-content-center align-items-center">
                <Col className="col-md-auto">
                    Total Records : <strong>{totalRecords}</strong>
                </Col>
                <Col className="col-md-auto">
                    Total Page : <strong>{controlledPageCount}</strong>
                </Col>
                <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                        <Button
                            color="primary"
                            onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                        >
                            {"<<"}
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => previousPage()} disabled={!canPreviousPage}
                        >
                            {"<"}
                        </Button>
                    </div>
                </Col>
                <Col className="col-sm-auto d-none d-md-block">
                    Page{" "}
                    <strong>
                        {/* {pageIndex + 1} of {pageOptions.length} */}
                    </strong>
                </Col>
                <Col className="col-md-auto">
                    <Input
                        type="number"
                        min={1}
                        style={{ width: 70 }}
                        value={currentPageIndex + 1}
                        onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                    />
                </Col>

                <Col className="col-sm-auto">
                    <div className="d-flex gap-1">
                        <Button color="primary" onClick={() => nextPage()} disabled={!canNextPage}>
                            {">"}
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => gotoPage(controlledPageCount - 1)} disabled={!canNextPage}
                        >
                            {">>"}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default DynamicTable;
