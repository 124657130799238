import Breadcrumb from "components/Common/Breadcrumb"
import SubmitLoader from "components/Common/submitLoader"
import { createUser, getAllUser, updateUser } from "helpers/backendHelpers/user"
import { SimpleStringValue } from "helpers/common_helper_functions"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useMemo, useState } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Container,
  Button,
} from "reactstrap"
import { CITIES_STATE } from "util/cities"
import dayjs from "dayjs"
import DynamicTable from "components/Common/DynamicTable"
import { getAllPlans } from "helpers/backendHelpers/plan"
import Select from "react-select"
import { createSubscriptionV2 } from "helpers/backendHelpers/manageSubscription"


const User = () => {
  const [users, setUsers] = useState([])
  const [plan, setPlan] = useState([])
  const [category, setCategory] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [subscriptionModal, setSubscriptionModal] = useState(false)
  const [save, setSave] = useState(false)
  const [city, setCity] = useState(CITIES_STATE)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [search, setSearch] = useState("");
  const [addSubscriptionData, setAddSubscriptionData] = useState({
    userId: null,
    planId: null,
    paymentMode: "cash"
  })

  useEffect(() => {
    document.title = "User | React Admin & User Template"
    fetchAllUser()
  }, [save, pageIndex, pageSize])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      userName: "",
      password: "",
      mobileNo: "",
      state: "",
      city: "",
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Please Enter user name"),
      password: Yup.string().required("Please Enter password"),
      mobileNo: Yup.string()
        .min(10)
        .max(10)
        .matches(/^[6-9]\d{9}$/, "Do not start value in 1,2,3,4,5")
        .required("Please Enter mobile number"),
    }),
    onSubmit: values => {
      handleAddUser(values)
    },
  })

  const today = dayjs().format("DD-MM-YYYY")
  const tomorrow = dayjs().add(1, "day").format("DD-MM-YYYY")
  const yesterday = dayjs().subtract(1, "day").format("DD-MM-YYYY")

  //* fetch all user
  const fetchAllUser = async (search = "") => {
    try {
      setLoading(true)
      const params = { page: pageIndex + 1, limit: pageSize, search }
      let response = await getAllUser(params)
      setTotalRecords(response?.payload?.count)
      response = response?.payload?.data

      setUsers(response)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }


  //* handle update status
  const handleUpdateUserStatus = async (id, status) => {
    try {
      setLoading(true)
      await updateUser(id, { isActive: status })
      setSave(!save)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const toggle = () => {
    setModal(!modal)
  }

  const subscriptionToggle = (userId) => {
    setAddSubscriptionData({ ...addSubscriptionData, userId })
    setSubscriptionModal(!subscriptionModal)
  }
  //* handle add click
  const handleAddButtonClick = () => {
    toggle()
  }

  const handleSubscriptionModalClick = (userId) => {
    subscriptionToggle(userId)
  }

  const handleAddUser = async data => {
    data["platform"] = "web"
    data["role"] = "user"
    data["createdByAdmin"] = true
    data.mobileNo = data.mobileNo.toString()
    try {
      setSubmitLoading(true)
      await createUser(data)
      toggle()
      validation.resetForm()
      setSave(!save)
      setSubmitLoading(false)
    } catch (error) {
      setSubmitLoading(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "User name",
        accessor: "userName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Mobile number",
        accessor: "mobileNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Referral Code",
        accessor: "referralCode",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Registration Date",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          return dayjs(cellProps.row.original.createdAt).format("DD-MM-YYYY")
        },
      },
      {
        Header: "Bill",
        accessor: "billCount",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Expiry Date",
        accessor: "planExpiryDate",
        filterable: true,
        Cell: cellProps => {
          // show also expiry yesterday , today , tommorow
          const data = cellProps?.row?.original?.planExpiryDate
          const expiryDate = dayjs(data).format("DD-MM-YYYY")

          if (data) {
            if (expiryDate === today) {
              return (
                <span className="badge bg-success">Plan Expiring today</span>
              )
            } else if (expiryDate === tomorrow) {
              return (
                <span className="badge bg-warning">Plan Expiring tomorrow</span>
              )
            } else if (expiryDate === yesterday) {
              return (
                <span className="badge bg-danger">Plan Expired yesterday</span>
              )
            } else {
              return expiryDate
            }
          } else {
            return "-"
          }
        },
      },
      {
        Header: "Status",
        accessor: "isActive",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input"
                id={`status_checkbox-${cellData._id}`}
                name={`status_checkbox-${cellData._id}`}
                defaultChecked={cellData.isActive}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateUserStatus(cellData._id, checked)
                }}
              />
            </div>
          )
        },
      },
      {
        Header: "",
        accessor: "Actions",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original

          return (
            <div className="text-end">
              <Button color="primary" outline onClick={() => handleSubscriptionModalClick(cellData?._id)}>Add Subscription</Button>
            </div>
          )
        },
      },
    ],
    []
  )

  const fetchPlans = async () => {
    try {
      let response = await getAllPlans()
      response = response?.payload?.data
      setPlan(response)
    } catch (error) {
      console.log('error::: ', error);
    }
  }

  const addSubscription = async () => {
    try {
      let response = await createSubscriptionV2(addSubscriptionData)
      if (response.success) {
        subscriptionToggle()
      }
    } catch (error) {
      console.log('error::: ', error);
    }
  }

  useEffect(() => {
    if (subscriptionModal) {
      fetchPlans()
    }
  }, [subscriptionModal])

  useEffect(() => {
    if (search === "") {
      fetchAllUser()
    }
  }, [search])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="User" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Input
                    style={{
                      right: 150,
                      position: "absolute",
                      width: "fit-content",
                    }}
                    type="select"
                    onChange={e => {
                      fetchAllUser(e.target.value)
                    }}
                  // onChange={e => {
                  //   setDropdownVal(e.target.value)
                  //   fetchInquiry(e.target.value)
                  // }}
                  // value={dropdownVal}
                  >
                    <option>Select Expiration </option>
                    <option value="today">Today</option>
                    <option value="tomorrow">Tomorrow</option>
                    <option value="yesterday">Yesterday</option>
                  </Input>
                  <DynamicTable
                    columns={columns}
                    data={users}
                    isLoading={loading}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    pageCount={Math.ceil(totalRecords / pageSize)}
                    canPreviousPage={pageIndex > 0}
                    canNextPage={pageIndex < Math.ceil(totalRecords / pageSize) - 1}
                    gotoPage={setPageIndex}
                    previousPage={() => setPageIndex(pageIndex - 1)}
                    nextPage={() => setPageIndex(pageIndex + 1)}
                    setPageSize={setPageSize}
                    state={{ pageIndex, pageSize }}
                    addButtonLabel={"Add User"}
                    handleAddButtonClick={handleAddButtonClick}
                    search={search}
                    handleSearchChange={(e) => {
                      setSearch(e.target.value)
                    }}
                    handleSearchClick={() => fetchAllUser(search || "")}
                    totalRecords={totalRecords}
                  />

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={modal} toggle={toggle}>
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggle} tag="h4">
              Add User
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12 mb-2">
                    <Label className="form-label">
                      userName<span className="text-danger">*</span>
                    </Label>

                    <Input
                      name="userName"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.userName &&
                          validation.errors.userName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.userName &&
                      validation.errors.userName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.userName}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col className="col-12 mb-2">
                    <Label className="form-label">
                      Password<span className="text-danger">*</span>
                    </Label>

                    <Input
                      name="password"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password &&
                          validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                      validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col className="col-12 mb-2">
                    <Label className="form-label">
                      Mobile Number<span className="text-danger">*</span>
                    </Label>

                    <Input
                      name="mobileNo"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.mobileNo &&
                          validation.errors.mobileNo
                          ? true
                          : false
                      }
                    />
                    {validation.touched.mobileNo &&
                      validation.errors.mobileNo ? (
                      <FormFeedback type="invalid">
                        {validation.errors.mobileNo}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col xs={12} className="mb-2">
                    <Label>
                      State
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="select"
                      name="state"
                      className="form-control my-1"
                      onChange={e => {
                        const filterCity = CITIES_STATE.filter(value => {
                          return value.state == e.target.value
                        })
                        setCity(filterCity)
                        validation.handleChange(e)
                      }}
                    >
                      <option>Select State</option>
                      {CITIES_STATE.map(item => (
                        <option value={item.state} key={item.id}>
                          {item.state}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Label>
                      City
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="select"
                      name="city"
                      className="form-control"
                      onChange={validation.handleChange}
                    >
                      <option>Select State</option>
                      {city.map(item => (
                        <option value={item.name} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        size="md"
                        color="danger"
                        type="button"
                        // disabled={submitLoading}
                        className="mx-2"
                        onClick={() => {
                          toggle()
                          validation.resetForm()
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal isOpen={subscriptionModal} toggle={subscriptionToggle}>
            <ModalHeader toggle={subscriptionToggle} tag="h4">
              Add Subscription
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col xxl={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Plan<span className="text-danger">*</span>
                      </Label>

                      <Select
                        name="plan"
                        options={plan.map(p => ({ label: p.name, value: p.planCategories }))}
                        onChange={selectedOption => {
                          const selectedPlan = plan.find(p => p.planCategories === selectedOption.value);
                          setCategory(selectedPlan.planCategories || []);
                          setAddSubscriptionData({ ...addSubscriptionData, planId: null });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xxl={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Categories<span className="text-danger">*</span>
                      </Label>

                      <Select
                        name="categories"
                        options={category.map(c => ({ label: c.listingAmount, value: c._id }))}
                        onChange={selectedOption => setAddSubscriptionData({ ...addSubscriptionData, planId: selectedOption.value })}
                        isDisabled={category?.length === 0}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <Label>Payment Mode</Label> <br />
                    <Input
                      name="paymentMode"
                      type="radio"
                      onChange={e => setAddSubscriptionData({ ...addSubscriptionData, paymentMode: e.target.value })}
                      defaultChecked={true}
                      value={"cash"}
                    />
                    <Label className="mx-3">Cash</Label>
                    <Input
                      name="paymentMode"
                      type="radio"
                      onChange={e => setAddSubscriptionData({ ...addSubscriptionData, paymentMode: e.target.value })}
                      defaultChecked={false}
                      value={"online"}
                    />
                    <Label className="mx-3">Online</Label>
                    <Input
                      name="paymentMode"
                      type="radio"
                      onChange={e => setAddSubscriptionData({ ...addSubscriptionData, paymentMode: e.target.value })}
                      defaultChecked={false}
                      value={"free"}
                    />
                    <Label className="mx-3">Free</Label>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        size="md"
                        color="danger"
                        type="button"
                        // disabled={submitLoading}
                        className="mx-2"
                        onClick={() => {
                          subscriptionToggle()
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn btn-success save-user"
                        onClick={addSubscription}
                      >
                        {"Save"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default User
